import { UtrvStatus } from '../../constants/status';
import { SafeUser } from '../../constants/users';
import { useToggle } from '../../hooks/useToggle';
import { SurveyActionData } from '../../model/surveyData';
import { SurveyPermissions } from '../../services/permissions/SurveyPermissions';

export const useToggleCongratulationsModal = ({
  survey,
  currentUser,
  toggleCompleteSurveys,
}: {
  survey?: Pick<SurveyActionData, 'roles' | 'initiativeId' | 'fragmentUniversalTrackerValues'>;
  currentUser?: Pick<SafeUser, '_id' | 'permissions'>;
  toggleCompleteSurveys: () => Promise<void>;
}) => {
  const [isModalOpen, toggleModal] = useToggle(false);

  const shouldOpenModal = ({ editingUtrvIds, verified }: { editingUtrvIds: string[]; verified: boolean }) => {
    if (!verified || !survey || !currentUser || !SurveyPermissions.canManage(survey, currentUser)) {
      return false;
    }

    const areOtherUtrvsVerified = survey.fragmentUniversalTrackerValues.every(
      (utrv) => editingUtrvIds.includes(utrv._id) || utrv.status === UtrvStatus.Verified
    );
    if (!areOtherUtrvsVerified) {
      return false;
    }

    const atLeastOneEditingUtrvNotVerified = survey.fragmentUniversalTrackerValues.some(
      (utrv) => editingUtrvIds.includes(utrv._id) && utrv.status !== UtrvStatus.Verified
    );

    return atLeastOneEditingUtrvNotVerified;
  };

  const openModal = ({ editingUtrvIds, verified }: { editingUtrvIds: string[]; verified: boolean }) => {
    if (
      shouldOpenModal({
        editingUtrvIds,
        verified,
      })
    ) {
      toggleModal();
    }
  };

  const toggleCongratulationsModal = (hasCompleted?: boolean) => {
    toggleModal();

    if (hasCompleted) {
      return toggleCompleteSurveys();
    }
  };

  return {
    isCongratulationsModalOpen: isModalOpen,
    openCongratulationsModal: openModal,
    toggleCongratulationsModal,
  };
};
