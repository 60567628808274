import React, { useReducer } from 'react';
import { AssuranceRoles } from '../../../constants/assurance';
import { ReduxStateLoadable } from '../../../reducers';
import G17Client from '../../../services/G17Client';
import { AssurancePermissions, AssurancePortfolioAssurer, UpdatePermissionData } from '../../../types/assurance';
import { Suggestion } from '../../search/SearchComponent';

export type State = ReduxStateLoadable<AssurancePermissions, AssurancePermissions>
export type PermissionChangeFn = (user: AssurancePortfolioAssurer, role: string) => void;
export interface SubmitData extends Partial<Suggestion> {
  error: boolean;
  submitting: boolean;
  errorMessage?: string,
  role: string,
}

const stakeholdersState: State = {
  loading: false,
  loaded: false,
  errored: false,
  data: {
    assurers: {
      [AssuranceRoles.ADMIN]: [] as AssurancePortfolioAssurer[],
      [AssuranceRoles.USER]: [] as AssurancePortfolioAssurer[],
    },
    organizationId: '',
    portfolioId: '',
  },
};

const initialSubmitData: SubmitData = { error: false, submitting: false, role: '' };

interface TeamMemberProps {
  children: JSX.Element | JSX.Element[];
}

export type TeamMemberContextProps = {
  portfolioData: State,
  submitData: SubmitData;
  dispatch: React.Dispatch<any>;
  updatePermissions: (data: UpdatePermissionData) => void;
}

export const ACTION = {
  SET_PORTFOLIO_DATA: 'portfolioData',
  SET_SUBMIT_DATA: 'submitData',
};

const initialState: TeamMemberContextProps = {
  portfolioData: stakeholdersState,
  submitData: initialSubmitData,
  dispatch: () => { },
  updatePermissions: () => { },
}

export const TeamMemberContext = React.createContext<TeamMemberContextProps>(initialState);

function reducer(state: any, action: any) {
  if (!action.type) {
    return state;
  }
  return { ...state, [action.type]: { ...state[action.type], ...action.payload } };
}

export default function TeamMemberContainer({ children }: TeamMemberProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const updatePermissions = (data: UpdatePermissionData) => {
    dispatch({
      type: ACTION.SET_SUBMIT_DATA,
      data: {
        submitting: true,
        error: false,
      },
    });
    G17Client.updateAssurancePermissions(state.portfolioData.data.portfolioId, data)
      .then(() => {
        dispatch({
          type: ACTION.SET_SUBMIT_DATA,
          payload: {
            submitting: false,
            error: false,
            errorMessage: '',
          },
        });
        dispatch({
          type: ACTION.SET_PORTFOLIO_DATA,
          payload: {
            loaded: false,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: ACTION.SET_SUBMIT_DATA,
          payload: {
            submitting: false,
            error: true,
            errorMessage: e.message,
          },
        });
      });
  };
  return (
    <TeamMemberContext.Provider
      value={{
        ...state,
        updatePermissions,
        dispatch,
      }}
    >
      {children}
    </TeamMemberContext.Provider>
  );
}
