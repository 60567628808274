import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { SurveyModelMinData, SurveyModelMinimalUtrv } from '../../../model/surveyData';
import UniversalTracker from '../../../model/UniversalTracker';
import { useAppSelector } from '../../../reducers';
import { CurrentUserData } from '../../../reducers/current-user';
import { FeaturePermissions } from '../../../services/permissions/FeaturePermissions';
import { SurveyAddAssurance } from '../../../types/survey';
import { QuestionMenuItem } from './QuestionMenuItem';
import { getMenuItems } from './toolbar/utils/getMenuItems';

export interface QuestionMenuProps {
  utr: UniversalTracker;
  utrv: Pick<SurveyModelMinimalUtrv, '_id' | 'universalTrackerId'>;
  user?: CurrentUserData;
  survey: Pick<SurveyModelMinData, '_id' | 'scope' | 'initiativeId' | 'completedDate' | 'stakeholders'>;
  isCompleted: boolean;
  handleRefreshQuestion: () => void;
  hasPortfolio: boolean;
  handleAddAssurance: (survey: SurveyAddAssurance) => void;
  isAssuranceStakeholder: boolean;
  getAssuranceStatus: (utrvId: string) => string | false;
  alternativeCode: string;
}

export const QuestionMenu = (props: QuestionMenuProps) => {
  const dispatch = useDispatch();
  const canAccessAssurance = useAppSelector(FeaturePermissions.canAccessAssurance);
  const [selected, setSelected] = useState('');

  const { utr, utrv, user, survey } = props;
  if (!utr || !utrv || !survey || !user) {
    return null;
  }

  const menuItems = getMenuItems({
    ...props,
    dispatch,
    canAccessAssurance,
    user,
  });

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle color='transparent' outline caret className='px-2'>
          <i className='fa fa-bars ml-1 mr-2' />
        </DropdownToggle>
        <DropdownMenu>
          {menuItems.map(item => (
            <QuestionMenuItem key={`action-item-${item.id}`} {...item} setSelected={() => setSelected(item.id)} />
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {menuItems.map(({ modal, id }) => {
        if (!modal) {
          return null;
        }
        const modalProps = {
          ...modal,
          isOpen: selected === id,
          toggle: () => setSelected('')
        };
        return selected === id ? <modal.component key={`action-modal-${id}`} {...modalProps} /> : null;
      })}
    </>
  );
};
