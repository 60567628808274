import SimpleTooltip from '../simple-tooltip';

interface Props {
  text: string;
  className?: string;
  light?: boolean;
}

export const InfoIcon = ({ text, className = '', light }: Props) => {
  return (
    <SimpleTooltip text={text} className={`${className} text-ThemeIconSecondary ml-2`}>
      <i className={`fa${light ? '-light' : ''} fa-info-circle`} />
    </SimpleTooltip>
  );
};
