import { HistoricalUtrs } from '../../../../../api/insights';
import { getMetricUnit } from './utils';
import { GridDashboardChartItem } from '../../../../../types/insight-custom-dashboard';
import { SingleUtrSingleValue } from './SingleUtrSingleValue';
import { MultiUtrsSingleValue } from './MultiUtrsSingleValue';

type Props = Pick<GridDashboardChartItem, 'calculation' | 'unitText' | 'variables' | 'note'> & {
  utrsData: HistoricalUtrs[];
  handleSetTarget?: () => void;
  hasSparkLine?: boolean;
  readOnly: boolean;
  altUnitText: string; // TODO: Should be removed when refactoring unitText
};

export const SingleValue = (props: Props) => {
  const {
    utrsData,
    calculation,
    unitText,
    variables,
    note,
    hasSparkLine = false,
    readOnly,
    handleSetTarget,
    altUnitText,
  } = props;

  if (!utrsData.length || !variables || !calculation) {
    return null;
  }

  const metricUnit = unitText || getMetricUnit(utrsData);
  const isSuffix = metricUnit === '%';
  const decimalPlaces = calculation.values[0].decimalPlaces ?? 0;

  const variableValuesArray = Object.values(variables);

  if (variableValuesArray.length === 1) {
    return (
      <SingleUtrSingleValue
        altUnitText={altUnitText}
        calculation={calculation}
        isSuffix={isSuffix}
        decimalPlaces={decimalPlaces}
        metricUnit={metricUnit}
        readOnly={readOnly}
        utrsData={utrsData}
        variableValue={variableValuesArray[0]}
        variables={variables}
        handleSetTarget={handleSetTarget}
        note={note}
        hasSparkLine={hasSparkLine}
      />
    );
  }

  return (
    <MultiUtrsSingleValue
      unitText={unitText}
      calculation={calculation}
      isSuffix={isSuffix}
      decimalPlaces={decimalPlaces}
      metricUnit={metricUnit}
      utrsData={utrsData}
      variables={variables}
      note={note}
      hasSparkLine={hasSparkLine}
    />
  );
};
