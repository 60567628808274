import { Bank, BankingSetting, BankType, Country } from '../types/banking-settings';
import { InitiativeData } from '../types/initiative';

export const getPolularBanks = (
  listOfBanks: Bank[],
  location: string[]
): Bank[] => {
  return location.length > 0 ? listOfBanks.filter(b => (b.popularCountryCodes ?? []).some(item => location.includes(item))) : [];
};

export const getOtherBanks = (listOfBanks: Bank[], location: string[]): Bank[] => {
  return location.length ? listOfBanks.filter(b => (b.popularCountryCodes ?? []).every(item => !location.includes(item))) : listOfBanks;
};

export const isManualBank = (bank: BankingSetting) => {
  return bank.type === BankType.ManualBank;
};

export const getInitiativeManualBanks = (initiative: InitiativeData): Bank[] => {
  return (initiative.bankingSettings ?? []).filter(isManualBank);
};

export const countriesDisplay = (countries: Country[] | undefined) => (countries ?? []).map(c => c.name).join(', ');
