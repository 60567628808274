import { DecimalInputProps } from '../../types/initiativeUniversalTracker';
import { generateDecimalExample } from '../survey/utils/input';
import { NumberInput } from './NumberInput';

interface Props extends DecimalInputProps {
  className?: string;
  inputWidth?: string;
  exampleText?: string;
  name?: string;
}

const generateExample = ({ value, hasError }: { value: number | undefined; hasError: boolean }) => {
  return hasError || value === undefined ? '-' : generateDecimalExample(value);
};

export const DecimalInput = ({
  decimal,
  error,
  name = 'value',
  disabled = false,
  className = '',
  inputWidth = '100%',
  exampleText = 'Example',
  onChange,
}: Props) => {
  const exampleProps = {
    value: decimal[name],
    hasError: !!error[name],
  };

  return (
    <div className={className}>
      <div style={{ width: inputWidth }}>
        <NumberInput
          name={name}
          disabled={disabled}
          onChange={onChange}
          value={decimal[name]}
          errorMessage={error[name]}
        />
      </div>
      <div>
        {exampleText}: {generateExample(exampleProps)}
      </div>
    </div>
  );
};
