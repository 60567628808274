import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { Bank, BankingSetting, Country } from '../types/banking-settings';
import { ApiResponse } from '../types/api';
import { axiosBaseQuery } from './axiosQuery';
import { Portfolio } from '../types/portfolio';

interface BankingSettingsMutationParams {
  initiativeId: string;
  bankingSettings: BankingSetting[];
}

interface AddManualBankMutationParams {
  initiativeId: string;
  bankName: string;
}

interface ProcessWorkflowParams {
  initiativeId: string;
  bankCode: string;
}

export interface AddWorkflowResponse {
  portfolio?: Pick<Portfolio, '_id' | 'name'>,
  assignedMetricGroupIds: string[],
}

export const bankingSettingsApi = createApi({
  reducerPath: 'bankingSettingsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['list-of-banks', 'countries'],
  endpoints: (builder) => ({
    getListOfBanks: builder.query<Bank[], void>({
      transformResponse: (response: ApiResponse<Bank[]>) => response.data,
      query: () => ({
        url: '/banking-settings/list',
        method: 'get',
      }),
      providesTags: ['list-of-banks']
    }),
    getCountries: builder.query<Country[], { countryCodes: string[] }>({
      transformResponse: (response: ApiResponse<Country[]>) => response.data,
      query: ({ countryCodes }) => ({
        url: '/banking-settings/countries',
        method: 'get',
        params: { countryCodes },
      }),
      providesTags: ['countries']
    }),
    updateBankingSettings: builder.mutation<BankingSetting[], BankingSettingsMutationParams>({
      query: (data) => {
        const { initiativeId, bankingSettings } = data;
        return {
          url: `/banking-settings/${initiativeId}`,
          method: 'patch',
          data: {
            bankingSettings,
          },
        };
      },
    }),
    addManualBank: builder.mutation<BankingSetting[], AddManualBankMutationParams>({
      query: (data) => {
        const { initiativeId, bankName } = data;
        return {
          url: `/banking-settings/${initiativeId}`,
          method: 'post',
          data: {
            bankName,
          },
        };
      },
    }),
    addWorkflow: builder.mutation<AddWorkflowResponse, ProcessWorkflowParams>({
      transformResponse: (response: ApiResponse<AddWorkflowResponse>) => response.data,
      query: (data) => {
        const { initiativeId, bankCode } = data;
        return {
          url: `/banking-settings/${initiativeId}/workflow/${bankCode}`,
          method: 'post'
        };
      },
    }),
    removeWorkflow: builder.mutation<Portfolio, ProcessWorkflowParams>({
      transformResponse: (response: ApiResponse<Portfolio>) => response.data,
      query: (data) => {
        const { initiativeId, bankCode } = data;
        return {
          url: `/banking-settings/${initiativeId}/workflow/${bankCode}`,
          method: 'delete'
        };
      },
    }),
    addAssignedMetrics: builder.mutation<{ message: string }, ProcessWorkflowParams>({
      transformResponse: (response: ApiResponse<{ message: string }>) => response.data,
      query: (data) => {
        const { initiativeId, bankCode } = data;
        return {
          url: `/banking-settings/${initiativeId}/workflow/${bankCode}/add-metrics`,
          method: 'post',
        };
      },
    }),
  }),
});

export const {
  useGetListOfBanksQuery,
  useGetCountriesQuery,
  useUpdateBankingSettingsMutation,
  useAddManualBankMutation,
  useAddWorkflowMutation,
  useRemoveWorkflowMutation,
  useAddAssignedMetricsMutation,
} = bankingSettingsApi;
