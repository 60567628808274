import { isNumeric } from '@utils/number';
import { HistoricalUtrs } from '../../../../../api/insights';
import { Calculation, GridDashboardChartItem } from '../../../../../types/insight-custom-dashboard';
import { tryCalculation } from '../../../../../utils/formula';
import NumberFormat from '../../../../../utils/number-format';
import { getSparklineChartProps } from '../../../../summary/insights/utils/helpers';
import { Note } from '../common/Note';
import { TakenFromText } from '../common/TakenFromText';
import { LineChart } from './LineChart';
import { getLatestActualUtrvs, getVariablesWithValues } from './utils';

interface Props extends Pick<GridDashboardChartItem, 'variables' | 'unitText' | 'note'> {
  utrsData: HistoricalUtrs[];
  hasSparkLine?: boolean;
  metricUnit: string;
  decimalPlaces: number;
  isSuffix: boolean;
  calculation: Calculation;
}

const getChartData = ({
  utrsData,
  variables,
  calculation,
}: Required<Pick<Props, 'utrsData' | 'variables' | 'calculation'>>) => {
  const { latestActualUtrvs = [], effectiveDate, period } = getLatestActualUtrvs(utrsData);

  const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs: latestActualUtrvs, fallback: '' });
  const chartData = tryCalculation({
    formula: calculation.values[0].formula || '',
    variables: variablesWithValues,
    fallback: '', // NOTE: Using zero value as fallback may cause confusion with actual zero value?
  });

  return { chartData, effectiveDate, period, variablesWithValues };
};

export const MultiUtrsSingleValue = (props: Props) => {
  const { utrsData, variables, unitText, calculation, note, metricUnit, isSuffix, hasSparkLine, decimalPlaces } = props;

  const {
    chartData,
    effectiveDate = '',
    period,
    variablesWithValues,
  } = getChartData({ utrsData, variables, calculation });

  return (
    <>
      <div className='w-100 h-100 d-flex flex-column justify-content-center'>
        {isNumeric(chartData) ? (
          <NumberFormat
            className='h1 m-0 text-center text-ThemeHeadingLight'
            value={chartData}
            suffix={isSuffix ? metricUnit : ''}
            decimalPlaces={decimalPlaces}
          />
        ) : (
          <div className='h1 m-0 text-center text-ThemeHeadingLight'>-</div>
        )}
        {!isSuffix ? <div className='text-sm text-center text-ThemeTextLight'>{metricUnit}</div> : null}
        {note ? (
          <Note note={note} variablesWithValues={variablesWithValues} className='text-center text-ThemeTextLight' />
        ) : null}
      </div>
      {hasSparkLine ? (
        <LineChart
          utrsData={utrsData}
          variables={variables}
          calculation={calculation}
          unitText={unitText}
          overrideOptions={getSparklineChartProps({ isRestated: false })}
        />
      ) : null}
      {effectiveDate ? <TakenFromText effectiveDate={effectiveDate} period={period} /> : null}
    </>
  );
};
