import { Col, Row } from 'reactstrap';
import SimpleTooltip from '../simple-tooltip';
import { EditOptionProps } from '../../types/initiative';

export const OrganizationRow = ({ label, rootOrg, initiative }: EditOptionProps) => {
  const isRoot = rootOrg._id === initiative._id;

  return (
    <Row className='ml-0 mb-3 mr-0 organization-row__wrapper'>
      <Col className='col-md-3 col-12 text-label-uppercase align-self-start'>{label}</Col>
      <Col className='col-md-7 col-10 text-right'>
        {isRoot ? <SimpleTooltip text='Can only edit organisation at root level'>{rootOrg.name}</SimpleTooltip> : rootOrg.name}
      </Col>
    </Row>
  );
};
