import React from 'react';
import { FormFeedback, FormText } from 'reactstrap';
import { generateDecimalHelperText, hasNumericInput } from '../../utils/input';
import UniversalTracker from '../../../../model/UniversalTracker';

const generateHelperText = ({
  universalTracker,
  inputName,
}: {
  universalTracker: Pick<UniversalTracker, 'getValueType' | 'getValueValidation'>;
  inputName?: string;
}) => {
  switch (true) {
    case hasNumericInput(universalTracker):
      return generateDecimalHelperText(universalTracker, inputName);
    default:
      return '';
  }
};

export const InputHelperText = ({
  invalidMessage = '',
  universalTracker,
  inputName,
}: {
  invalidMessage: string | undefined;
  universalTracker: Pick<UniversalTracker, 'getValueType' | 'getValueValidation'> | undefined;
  inputName?: string;
}) => {
  if (invalidMessage) {
    return <FormFeedback>{invalidMessage}</FormFeedback>;
  }

  const helperText = universalTracker ? generateHelperText({ universalTracker, inputName }) : '';

  return helperText ? <FormText className='d-block w-100 ml-0 text-ThemeTextPlaceholder'>{helperText}</FormText> : null;
};
