import { SearchBox } from '../settings-sidebar';
import { FormFeedback, Input, Label } from 'reactstrap';
import { isValidDate } from '../stats/utils';
import './styles.scss';

type FormEventHandler = (e: React.FormEvent<HTMLInputElement>) => void;
type ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => void;
interface SystemLogHeaderProps {
  searchText: string,
  fromDate?: string,
  toDate?: string,
  onSearchTextChange: FormEventHandler,
  onFromDateChange: ChangeEventHandler,
  onToDateChange: ChangeEventHandler,
}

export const SystemLogHeader = ({ searchText, fromDate, toDate, onSearchTextChange, onFromDateChange, onToDateChange }: SystemLogHeaderProps) => {
  return (
    <div className='system-log-header'>
      <div className='search-box'>
        <Label>Search logs: </Label>
        <SearchBox
          handleOnChange={onSearchTextChange}
          value={searchText}
        />
      </div>
      <div className='date-filter'>
        <div className='from-date'>
          <Label for='fromDate'>Between:</Label>
          <Input invalid={!isValidDate(fromDate)} value={fromDate ?? ''} name='fromDate' type='date' placeholder='dd/mm/yyyy' onChange={onFromDateChange} />
          <FormFeedback tooltip>Please input a data with format is dd/mm/yyyy</FormFeedback>
        </div>
        <div className='to-date'>
          <Label for='toDate'>-</Label>
          <Input invalid={!isValidDate(toDate)} value={toDate ?? ''} name='toDate' type='date' placeholder='dd/mm/yyyy' onChange={onToDateChange} />
          <FormFeedback tooltip>Please input a data with format is dd/mm/yyyy</FormFeedback>
        </div>
      </div>
    </div>
  );
}