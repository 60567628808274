import { Button } from 'reactstrap';
import FileZone from '../profile/FileZone';
import SimpleTooltip from '../simple-tooltip';
import { DropzoneOptions } from 'react-dropzone';
import { bytesToHumanReadable } from '../../utils';
import classNames from 'classnames';

export enum FileDropzoneVariant {
  Normal = 'normal',
  Icon = 'icon',
}

export const MINE_TYPES = {
  EXCEL: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-excel': ['.xls'],
  },
  CSV: {
    'text/csv': ['.csv'],
  },
};

const defaultDropText = 'Attach excel document by dragging and dropping, or';

interface ImportFileDropzoneProps extends Pick<DropzoneOptions, 'accept'> {
  variant?: FileDropzoneVariant;
  importFile: File | undefined;
  onFilesAdded: (files: File[]) => void;
  onFileRemove?: () => void;
  hint?: string | JSX.Element;
  classes?: Record<string, string>;
  dropText?: string;
}

interface VariantFileDropzoneProps extends ImportFileDropzoneProps {
  handleRemoveFile?: (e: React.MouseEvent) => void;
}

export const ImportFileDropzone = (props: ImportFileDropzoneProps) => {
  const { variant = FileDropzoneVariant.Normal, accept = MINE_TYPES.EXCEL, onFileRemove } = props;

  const handleRemoveFile = onFileRemove
    ? (e: React.MouseEvent) => {
        e.stopPropagation();
        onFileRemove();
      }
    : undefined;

  switch (variant) {
    case FileDropzoneVariant.Icon:
      return <IconFileDropzone {...props} accept={accept} handleRemoveFile={handleRemoveFile} />;
    case FileDropzoneVariant.Normal:
    default:
      return <NormalFileDropzone {...props} accept={accept} handleRemoveFile={handleRemoveFile} />;
  }
};

const IconFileDropzone = (props: VariantFileDropzoneProps) => {
  const { accept, importFile, onFilesAdded, handleRemoveFile, hint, classes } = props;

  return (
    <FileZone
      accept={accept}
      handleFilesAdded={onFilesAdded}
      dropText={() => (
        <div className={classNames('w-100 d-flex flex-wrap justify-content-center p-1', classes?.wrapper)}>
          <div>
            <span className='border border-ThemeBorderDefault p-2 rounded'>
              <i className='text-lg text-ThemeTextMedium fa-light fa-upload px-1' />
            </span>
          </div>
          <div className='w-100 mt-1 text-center text-ThemeTextMedium'>
            {importFile ? (
              <div className='d-flex justify-content-center align-items-center mt-2'>
                <div className='text-md'>{importFile.name}</div>
                {handleRemoveFile ? (
                  <SimpleTooltip text='Click to remove file' className='w-auto ml-2'>
                    <i className='fa fa-close text-danger mt-1' onClick={handleRemoveFile} />
                  </SimpleTooltip>
                ) : null}
              </div>
            ) : (
              <div className='mt-2 text-md'>
                <Button type='button' size='sm' color='link' className='p-0 mx-1 border-0'>
                  Click to add file
                </Button>{' '}
                or drag and drop
              </div>
            )}
            <div className='mt-1 text-sm'>
              {importFile ? <span>{bytesToHumanReadable(importFile.size)}</span> : <span>{hint}</span>}
            </div>
          </div>
        </div>
      )}
      renderFileList={() => <></>}
    />
  );
};

const NormalFileDropzone = (props: VariantFileDropzoneProps) => {
  const { handleRemoveFile, dropText = defaultDropText } = props;

  return props.importFile ? (
    <div className='d-flex align-items-baseline mt-3'>
      <i className='fa fa-file-import text-muted ml-2' />
      <div className='mx-2'>{props.importFile.name}</div>
      {handleRemoveFile ? (
        <SimpleTooltip text='Click to remove file'>
          <i className='fa fa-close text-danger' onClick={handleRemoveFile} />
        </SimpleTooltip>
      ) : null}
    </div>
  ) : (
    <FileZone
      accept={props.accept}
      handleFilesAdded={props.onFilesAdded}
      dropText={() => (
        <div className='d-flex'>
          {dropText}
          <Button size='sm' color='link' className='p-0 mx-1 border-0' onClick={() => {}}>
            upload your template here
          </Button>
        </div>
      )}
      renderFileList={() => <></>}
    />
  );
};
