import { DecimalInputProps } from '../../types/initiativeUniversalTracker';
import { TableColumn } from '../survey/form/input/table/InputInterface';
import { DecimalInput } from './DecimalInput';

interface Props extends DecimalInputProps {
  tableColumns: TableColumn[];
}

export const DecimalTableInput = ({ tableColumns, ...inputProps }: Props) => {
  return (
    <div>
      <div className='my-3'>Leave blank to not enforce for an input.</div>
      {tableColumns.map((col) => (
        <div key={col.code} className='mt-2'>
          <div>{col.name}</div>
          <DecimalInput
            name={col.code}
            className='d-flex align-items-baseline gap-3'
            inputWidth='300px'
            exampleText={'e.g'}
            {...inputProps}
          />
        </div>
      ))}
    </div>
  );
};
