import React, { CSSProperties } from 'react';
import { getSDGShortTitle } from '../../constants/sdg-data';
import { Column } from '../tracking-list';
import PixelSpacer from './1x1.png';
import { Progress } from 'reactstrap';
import { ScorecardGoal } from '../../types/scorecard';

interface IconProps {
  sdgCode: number
  goal?: ScorecardGoal | Partial<ScorecardGoal>
  materialityPc: number,
  displayProgressBars?: boolean,
  displayMaterialityTachimeter?: boolean,
  displayMaterialityText?: boolean,
  displayTargets?: boolean;
  onClick?: () => void,
  selected?: boolean,
  disabled?: boolean
}
const isMaterialityHigh = (score: number) => score > 66
const isMaterialityMedium = (score: number) => score > 33
const isMaterialityLow = (score: number) => score > 0

class SDGControlStripIcon extends React.Component<IconProps> {
  render() {
    const { sdgCode, materialityPc, displayProgressBars, displayMaterialityTachimeter, displayMaterialityText, onClick, selected, disabled } = this.props;
    const key = `sdg-control-strip-${sdgCode}`;
    const className = selected ? `sdg-bg-icon-${sdgCode}` : `sdg-bg-icon-outline-${sdgCode} ${disabled !== true ? 'sdg-hover' : ''}`;
    let tooltipText: string | JSX.Element = '';
    let progressBar: string | JSX.Element = '';

    if (displayProgressBars === true) {
      const contributionPc = this.getContribution();
      const { scopePc, inScope, totalScope } = this.getScope();
      tooltipText = this.getTooltip(sdgCode, contributionPc, inScope, totalScope, materialityPc);
      progressBar = (<div>
        {this.renderProgressBar(sdgCode, scopePc, contributionPc)}
      </div>);
    } else {
      tooltipText = <strong>SDG {sdgCode}: {getSDGShortTitle(sdgCode)}</strong>;
    }

    return (
      <Column key={key} tooltip={tooltipText} onClick={disabled !== true ? onClick : undefined} className='sdg-control-strip-icon'>
        {displayMaterialityTachimeter !== false && this.renderTachometer(materialityPc)}
        <div className={`sdg-icon ${className}`}><img alt='s' src={PixelSpacer} /></div>
        { displayMaterialityText !== false ? this.renderMaterialityText(materialityPc) : null}
        {progressBar}
      </Column>
    );
  }

  getTooltip = (sdgCode: number, contributionPc: number, inScope: number, totalScope: number, materialityPc: number) => {
    const tooltipStyle: CSSProperties = {
      whiteSpace: 'nowrap'
    };
    const materialityTxt = isMaterialityHigh(materialityPc) ? 'High' : isMaterialityMedium(materialityPc) ? 'Medium' : isMaterialityLow(materialityPc) ? 'Low' : 'None';

    const scopeText = totalScope ? `${inScope || 0} of ${totalScope} Targets` : 'Not in scope';

    return <div style={{ minWidth: '200px' }}>
      <div>
        <strong>SDG {sdgCode}: {getSDGShortTitle(sdgCode)}</strong>
      </div>
      <div className='row'>
        <div style={tooltipStyle} className='col-6 text-right pr-1'>Materiality:</div>
        <div className='col-6 text-left pl-1'>{materialityTxt}</div>
      </div>
      <div className='row'>
        <div style={tooltipStyle} className='col-6 text-right pr-1'>Contribution:</div>
        <div className='col-6 text-left pl-1'>{contributionPc}%</div>
      </div>
      {this.props.displayTargets ? <div className='row'>
        <div style={tooltipStyle} className='col-6 text-right pr-1'>Scope:</div>
        <div className='col-6 text-left pl-1'>{scopeText}</div>
      </div>: null}
    </div>
  }

  getContribution = () => {
    const { goal } = this.props;
    if (!goal) {
      return 0;
    }
    return goal.actual ? Math.round(goal.actual) : 0;
  }

  getScope = () => {
    const { goal } = this.props;
    if (!goal || !goal.targets) {
      return {
        inScope: 0,
        totalScope: 0,
        scopePc: 0,
      }
    }
    const visibleTargets = goal.targets;
    const totalScope = visibleTargets.length;
    const inScope = visibleTargets.reduce((a, t) => a + (t.inScope ? 1 : 0), 0);
    if (!totalScope || !inScope) {
      return {
        inScope: 0,
        totalScope: 0,
        scopePc: 0,
      }
    }
    const scopePc = Math.round(Math.round(100 * inScope / totalScope));
    return {
      inScope,
      totalScope,
      scopePc
    }
  }

  getMaterialityText = (materialityPc: number) => {
    return isMaterialityHigh(materialityPc)  ? 'High' : isMaterialityMedium(materialityPc)  ? 'Med' : isMaterialityLow(materialityPc) ? 'Low' : '-';
  }

  renderTachometer = (materialityPc: number) => {
    const materialityTxt = this.getMaterialityText(materialityPc);
    const materialityClassName = isMaterialityHigh(materialityPc) ? 'text-danger' :isMaterialityMedium(materialityPc) ? 'text-warning' : isMaterialityLow(materialityPc) ? 'text-success' : 'text-muted';
    return <div className='materiality-container'>
      <i className={`fa fa-tachometer-alt mr-1 ${materialityClassName}`} />
      <span className={`strong ${materialityPc <= 33 ? 'text-ThemeBgDisabled' : ''}`}>{materialityTxt}</span>
    </div>
  }

  renderMaterialityText = (materialityPc: number) => {
    const materialityTxt = this.getMaterialityText(materialityPc);
    const materialityClassName = isMaterialityHigh(materialityPc) ? 'text-high' : isMaterialityMedium(materialityPc) ? 'text-medium' : isMaterialityLow(materialityPc) ? 'text-low' : 'text-muted';
    return <div className='materiality-text-container'>
      <span className={materialityClassName}>{materialityTxt}</span>
    </div>
  }

  renderProgressBar = (sdgCode: number, scopePc: number, contributionPc: number) => {
    const progressPc = scopePc * (contributionPc/100);
    const remainderPc = scopePc - progressPc;
    return <div style={{ marginBottom: '1px', marginTop: '4px' }}>
      <Progress multi >
        {!!progressPc && <Progress bar value={progressPc} barClassName={`sdg-bg-${sdgCode}-5`} />}
        {!!remainderPc && <Progress bar value={remainderPc} color='white' barClassName={`sdg-border-${sdgCode}-5`} />}
      </Progress>
    </div>
  }
}

export default SDGControlStripIcon;
