import { DropdownItem } from 'reactstrap';
import SimpleTooltip from '../../simple-tooltip';
import { ToolbarButtonProps } from './toolbar/ToolbarButton';

export const QuestionMenuItem = (props: ToolbarButtonProps & { setSelected: () => void }) => {
  const { modal, contents, tooltip, btnClass, onClick, onMouseOver, disabled, setSelected } = props;

  return (
    <div className='toolbar-menu-item'>
      <SimpleTooltip text={tooltip}>
        <DropdownItem
          className={`toolbar-status-button ${btnClass ?? ''}`}
          disabled={disabled}
          onClick={modal ? () => setSelected() : onClick}
          onMouseOver={onMouseOver ? onMouseOver : () => { }}
        >
          {contents}
        </DropdownItem>
      </SimpleTooltip>
    </div>
  );
};
