import React from 'react';
import { FormFeedback, FormGroup, Input } from 'reactstrap';

interface Props {
  name: string;
  value: number | undefined;
  disabled: boolean;
  min?: number;
  max?: number;
  errorMessage: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NumberInput = (props: Props) => {
  const { errorMessage = '', min = 0, max = 5, ...inputProps } = props;

  return (
    <FormGroup className='mt-1'>
      <Input
        {...inputProps}
        type='number'
        invalid={!!errorMessage}
        className='mr-2'
        placeholder='Key in value'
        min={min}
        max={max}
        value={inputProps.value ?? ''}
      />
      <FormFeedback className='mt-2'>{errorMessage}</FormFeedback>
    </FormGroup>
  );
};
