import UniversalTracker from '../../../model/UniversalTracker';
import { isDefined } from '../../../utils';
import { hasNumericColumnType, isNumericValueType } from '../../../utils/universalTracker';
import { getUtrDecimal } from '../../utr-decimal/utils';
import { ErrorMessageType } from '../form/input/InputProps';

const DECIMAL_EXAMPLE = '83';
const DECIMAL_STRING = '12345';

export const hasNumericInput = (utr: Pick<UniversalTracker, 'getValueType' | 'getValueValidation'>) =>
  isNumericValueType(utr.getValueType()) || hasNumericColumnType({ valueValidation: utr.getValueValidation() });

const isValidValueWithDecimal = ({
  utr,
  value,
  name,
}: {
  utr: Pick<UniversalTracker, 'getValueType' | 'getValueValidation'>;
  value: string | number | undefined;
  name: string;
}) => {
  if (!value) {
    return true;
  }

  if (!hasNumericInput(utr)) {
    return true;
  }

  const decimal = getUtrDecimal(utr, name);

  if (decimal === undefined) {
    return true;
  }

  const pattern = decimal ? `^\\d+\\.\\d{${decimal}}$` : '^\\d+$';
  const decimalRegExp = new RegExp(pattern);
  return decimalRegExp.test(value.toString());
};

export const generateDecimalErrorMessage = ({
  utr,
  input,
}: {
  utr: Pick<UniversalTracker, 'getId' | 'getValueType' | 'getValueValidation'>;
  input: { [key: string]: string | number | undefined } | undefined;
}) => {
  if (!input) {
    return;
  }

  return Object.keys(input).reduce((acc, name) => {
    if (isValidValueWithDecimal({ utr, value: input[name], name })) {
      acc[name] = '';
    } else {
      acc[name] = generateDecimalHelperText(utr, name);
    }
    return acc;
  }, {} as ErrorMessageType);
};

export const generateDecimalExample = (decimal: number) => {
  if (decimal === 0) {
    return DECIMAL_EXAMPLE;
  }

  const fractionalPart = DECIMAL_STRING.substring(0, decimal);

  return DECIMAL_EXAMPLE.concat('.').concat(fractionalPart);
};

export const generateDecimalHelperText = (
  utr: Pick<UniversalTracker, 'getValueType' | 'getValueValidation'> | undefined,
  inputName?: string
) => {
  const decimal = getUtrDecimal(utr, inputName);
  return isDefined(decimal) ? `Requirement: ${decimal} decimal places (example ${generateDecimalExample(decimal)})` : '';
};
