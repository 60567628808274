import { useState } from 'react';
import { Button } from 'reactstrap';
import { AssuranceRoles } from '../../../constants/assurance';
import { useAppSelector } from '../../../reducers';
import { getCurrentUser } from '../../../selectors/user';
import { AssurancePortfolioAssurer } from '../../../types/assurance';
import SimpleTooltip from '../../simple-tooltip';
import { ConfirmPopup } from './ConfirmPopup';
import { PermissionChangeFn } from './TeamMemberContainer';

interface AssuranceUserRowProps {
  user: AssurancePortfolioAssurer;
  handleRemoveUser: PermissionChangeFn;
  role: string;
  isAdmin: boolean;
  isLastAdmin: boolean;
}

const getUserText = (user: AssurancePortfolioAssurer) => {
  if (user.email) {
    return (
      <span className={'pendingText'}>
        <i title='Invitation sent and waiting confirmation' className='fas fa-paper-plane pending mr-1' />
        {user.email} (pending)
      </span>
    );
  }

  if (user.active) {
    return (
      <span>
        {user.firstName} {user.surname}{' '}
      </span>
    );
  }

  return (
    <span className={'user-inactive'}>
      {user.firstName} {user.surname}{' '}
      <SimpleTooltip
        id={`optionTooltip-${user._id}`}
        text={'User is not active'}
        component={<i className='fas fa-info-circle' />}
      />
    </span>
  );
};

export const AssuranceUserRow = ({ user, handleRemoveUser, role, isAdmin, isLastAdmin }: AssuranceUserRowProps) => {
  const currentUser = useAppSelector(getCurrentUser);
  const [isOpen, setOpen] = useState(false);
  const toggleModal = () => setOpen((prevState) => !prevState);

  const shouldRenderRemove = () => {
    if (role === AssuranceRoles.ADMIN && isLastAdmin) {
      return false;
    }

    if (isAdmin) {
      return true;
    }

    return currentUser?._id === user._id;
  }

  const handleRemove = () => {
    if (!currentUser || (!user._id && !user.email)) {
      return;
    }
    if (user._id === currentUser._id && !(user.isAdmin && user.isUser)) {
      return toggleModal();
    }
    return removeUser();
  }

  const removeUser = () => shouldRenderRemove() && handleRemoveUser(user, role);

  return (
    <>
      <Button
        color='link'
        disabled={!shouldRenderRemove()}
        className='mx-0 p-0'
        onClick={handleRemove}
      >
        <i className='fas fa-times text-ThemeDangerMedium' />
      </Button>
      {getUserText(user)}
      <ConfirmPopup isOpen={isOpen} toggleModal={toggleModal} removeUser={removeUser} />
    </>
  );
};
