import { matchPath, useLocation } from 'react-router-dom';
import Select, {
  components,
  GroupBase,
  SingleValue,
  ValueContainerProps,
  StylesConfig,
  ControlProps,
  CSSObjectWithLabel,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import { NavSelectOption } from '.';

interface NavSelectProps {
  renderedRoutes: NavSelectOption[];
  onSelectRoute: (route: SingleValue<NavSelectOption>) => void;
  isOptionDisabled: (route: NavSelectOption) => boolean;
}

type IsNotMulti = false;

const royalBlue = '#2971E5';
const white = '#FFF';

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<NavSelectOption, false, GroupBase<NavSelectOption>>) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <i className='fas fa-bars mr-2 fa-2x' aria-hidden='true' style={{ position: 'relative' }} />}
        {children}
      </components.ValueContainer>
    )
  );
};

export const NavSelect = (props: NavSelectProps) => {
  const { renderedRoutes, onSelectRoute, isOptionDisabled } = props;
  const location = useLocation();

  const selectedRoute = renderedRoutes.find((route) => !!matchPath(location.pathname, route));

  const customStyles: StylesConfig<NavSelectOption, IsNotMulti> = {
    container: (provided: CSSObjectWithLabel) => ({
      ...provided,
      alignSelf: 'center',
    }),
    control: (
      provided: CSSObjectWithLabel,
      state: ControlProps<NavSelectOption, IsNotMulti, GroupBase<NavSelectOption>>
    ) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      backgroundColor: state.selectProps.menuIsOpen ? royalBlue : white,
      '&:hover': {
        backgroundColor: royalBlue,
        '[class*="-singleValue"], i': {
          color: white,
          transition: 'color 100ms ease-in-out',
        },
      },
    }),
    option: (
      provided: CSSObjectWithLabel,
      state: OptionProps<NavSelectOption, IsNotMulti, GroupBase<NavSelectOption>>
    ) => ({
      ...provided,
      borderLeft: state.isSelected ? `3px solid ${royalBlue}` : '3px solid transparent',
      color: state.isSelected ? royalBlue : 'inherit',
      backgroundColor: 'transparent',
      '&:hover': {
        borderLeft: `3px solid ${royalBlue}`,
        color: royalBlue,
        backgroundColor: 'transparent',
        transition: 'color 100ms ease-in-out',
      },
    }),
    indicatorsContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: 'none',
    }),
    valueContainer: (
      provided: CSSObjectWithLabel,
      state: ValueContainerProps<NavSelectOption, IsNotMulti, GroupBase<NavSelectOption>>
    ) => ({
      ...provided,
      display: 'flex',
      padding: '2px 10px',
      color: state.selectProps.menuIsOpen ? white : royalBlue,
    }),
    singleValue: (
      provided: CSSObjectWithLabel,
      state: SingleValueProps<NavSelectOption, IsNotMulti, GroupBase<NavSelectOption>>
    ) => {
      return {
        ...provided,
        color: state.selectProps.menuIsOpen ? white : royalBlue,
      };
    },
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      marginTop: 0,
    }),
  };

  return (
    <Select
      placeholder={'Select setting'}
      className='w-20'
      isMulti={false}
      styles={customStyles}
      onChange={onSelectRoute}
      value={selectedRoute ?? null}
      isSearchable={false}
      components={{ ValueContainer }}
      options={renderedRoutes}
      isOptionDisabled={isOptionDisabled}
    />
  );
};
