import { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import Table from '../../table/Table';
import { AddMemberPopup } from './AddMemberPopup';
import { AssurancePortfolioAssurer, UpdatePermissionData } from '../../../types/assurance';
import { useAppSelector } from '../../../reducers';
import { getCurrentUser } from '../../../selectors/user';
import { AssuranceUserRow } from './AssuranceUserRow';
import { AssuranceRoles } from '../../../constants/assurance';
import { ACTION, TeamMemberContext } from './TeamMemberContainer';
import { Action } from '../../../constants/action';

interface AddMemberPopupType {
  role: AssuranceRoles;
}

export const TeamMemberPopup = (props: AddMemberPopupType) => {
  const { role } = props;
  const { dispatch, portfolioData, updatePermissions } = useContext(TeamMemberContext);
  const currentUser = useAppSelector(getCurrentUser);
  const [isOpen, setOpen] = useState(false);
  const toggleModal = () => {
    dispatch({
      type: ACTION.SET_SUBMIT_DATA,
      payload: { submitting: false, error: false, errorMessage: '' },
    });
    setOpen((prevState) => !prevState);
  };

  const getLabel = (role: string) => role.charAt(0).toUpperCase() + role.slice(1);
  const isAdmin = portfolioData.data.assurers[AssuranceRoles.ADMIN].some((assurer) => assurer._id === currentUser?._id);
  const isLastAdmin = portfolioData.data.assurers[AssuranceRoles.ADMIN].length <= 1;

  const removePermission = (user: AssurancePortfolioAssurer, role: string) =>
    updatePermissions({
      userId: user._id,
      email: user.email,
      action: Action.Remove,
      role,
    } as UpdatePermissionData);

  return (
    <div className='user-container'>
      <div className='user-list'>
        <Table
          data={portfolioData.data.assurers[role]}
          columns={[
            {
              Header: getLabel(role),
              resizable: false,
              id: 'name',
              sortable: true,
              accessor: (user: AssurancePortfolioAssurer) => (
                <AssuranceUserRow
                  user={user}
                  handleRemoveUser={removePermission}
                  role={role}
                  isAdmin={isAdmin}
                  isLastAdmin={isLastAdmin}
                />
              ),
            },
          ]}
        />
      </div>
      <div className='pt-2 text-right'>
        <Button disabled={!isAdmin} onClick={toggleModal}>
          Add {getLabel(role)}
        </Button>
      </div>
      <AddMemberPopup
        isOpen={isOpen}
        toggleModal={toggleModal}
        role={role}
      />
    </div>
  );
};
