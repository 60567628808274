import { PortfolioCompaniesFilters } from '../actions/portfolio-tracker';
import { ApiResponse } from '../types/api';
import { InitiativeCompany } from '../types/initiative';
import { g17ecoApi } from './g17ecoApi';

interface GetPortfolioCompanies {
  portfolioId: string;
  recursive?: boolean;
  filters?: PortfolioCompaniesFilters;
}

const transformResponse = <T>(response: ApiResponse<T>) => response.data;

export const portfolioApi = g17ecoApi
.enhanceEndpoints({
  addTagTypes: ['portfolio-companies'],
})
.injectEndpoints({
  endpoints: (builder) => ({
    getPortfolioCompanies: builder.query<InitiativeCompany[], GetPortfolioCompanies>({
      transformResponse,
      query: ({portfolioId, recursive = true, filters}) => ({
        url: `portfolios/${portfolioId}/companies${recursive ? '/recursive' : ''}`,
        method: 'get',
        params: {
          ...filters
        }
      }),
      providesTags: ['portfolio-companies']
    })
  }),
});

export const { useGetPortfolioCompaniesQuery } = portfolioApi;
